<template>
    <div class="box">
        <div class="title">
            电子签名
        </div>
        <div class="canvas">
            <vue-esign ref='esign' style="display:inline" :width='sign.width' :height='sign.height'
                :isCrop='sign.isCrop' :lineWidth='sign.lineWidth' :lineColor='sign.lineColor'
                :bgColor.sync='sign.bgColor'></vue-esign>
        </div>
        <div class="footer">
            <el-button type="info" round size="small" @click="resetHandle">重新签名</el-button>
            <el-button type="danger" round size="small" @click="generateHandle">确认签名</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'signature',
        data() {
            return {
                token:'',
                sign: {
                    width: 400,
                    height: 200,
                    lineWidth: 4,
                    lineColor: '#000000',
                    bgColor: '#ffffff',
                    img: '',
                    isCrop: true
                }
            }
        },
        created() {
            this.token = this.$route.query['token'];
            let param = new URLSearchParams();
            param.append("tokenID", this.token);
            this.$api.user.getToken(param).then(res=>{
                if(res.data.code == 200){
                    
                }
            })
        },
        methods: {
            // 生成电子签名
            resetHandle() {
                this.$refs.esign.reset();

                this.$nextTick(() => {
                    this.sign = {
                        width: 1000,
                        height: 200,
                        lineWidth: 4,
                        lineColor: '#000000',
                        bgColor: '#ffffff',
                        img: '',
                        isCrop: true
                    }
                })
            },
            generateHandle() {
                this.$refs.esign.generate().then(resimg => {
                    let params = {
                        token: this.token,
                        signature: resimg
                    }
                    this.$api.reviewPage.mobileSaveSignature(params)
                        .then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("保存成功");
                            }else{
                                this.$message.warning("报错");
                            }
                        }).catch(err => {
                            this.$message.warning("服务器维护！");
                        })
                }).catch(err => {
                    console.log(err);
                })
            },
        }
    }
</script>


<style scoped>
    .box {
        /* background-color: rgb(209, 209, 209); */
        height: 100vh;
        background: rgb(234 234 234);
    }

    .title {
        font-size: 24px;
        text-align: center;
        padding: 20px;
        font-family: cursive;
    }

    .canvas {
        margin: 0 auto;
        text-align: center;
    }

    .footer {
        text-align: center;
        margin-top: 10px;
    }
</style>